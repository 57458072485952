{
  "header": {
    "menu": {
      "developers": "Застройщики",
      "advantages": "Преимущества",
      "catalogs": "Каталоги",
      "functionality": "Функционал"
    },
    "join": "Подключиться"
  },
  "content": {
    "header": {
      "buttonTransparent": "Обрабатывайте заявки в 5 раз быстрее с Genie Map",
      "title": "Единый сервис для агентств недвижимости в ОАЭ",
      "description": "Интерактивная карта с глубокой фильтрацией для быстрого поиска off-plan проектов от девелоперов и агентств недвижимости, генератор брендированных каталогов, платформа для проведения онлайн-конференций с клиентами и все, что нужно агентству недвижимости для комфортной и эффективной работы.",
      "join": "Подключиться",
      "learnMore": "Узнать больше"
    },
    "developers": {
      "title": "Внушительная база застройщиков",
      "description": "На нашей платформе вы найдете 260+ девелоперов, 1250+ проектов и лончей, <br> которые вы можете добавлять <a href='#catalogs'>в свои брендированные каталоги."
    },
    "service": {
      "title": "Первоклассный сервис <br /> для агентств недвижимости",
      "description": "Знаем специфику рынка, потребности брокеров и агентств. Ежедневно в режиме реального времени пополняем базу застройщиков и актуализируем информацию, чтобы у вас всегда был доступ к самым новым проектам и данным.",
      "item1Title": "Фильтрация",
      "item1Description": "Детально настраиваемые фильтры помогут вам подобрать идеальный проект для клиента. Фильтруйте по эмирату, району, девелоперу, цене, по сроку сдачи, выберите лонч или готовый проект, и многое другое.",
      "item2Title": "Знание лончей",
      "item2Description": "Ежедневно добавляем новые лончи, расширяем диапазон проектов, которые можно предложить клиенту, и позволяем собирать заявки на EOI задолго до официального старта продаж.",
      "item3Title": "Индивидуальная подборка для клиента",
      "item3Description": "Брокер может сформировать для клиента каталог из нескольких проектов и выслать один <a href='#catalogs'>файл</a> со всей необходимой информацией, полностью подходящей под запрос покупателя.",
      "item4Title": "Владение информацией",
      "item4Description": "Genie Map — это база знаний по застройщикам и районам. К каждому проекту прикреплено уникальное описание, позволяющее брокеру рассказать клиенту о районе и близлежащей инфраструктуре."
    },
    "catalog": {
      "title": "Брендированные каталоги вашего агентства",
      "description": "5 секунд – и у вас на руках полностью брендированный каталог вашего <br> агентства со всеми выбранными проектами и юнитами застройщиков.",
      "join": "Начать генерировать каталоги для своих клиентов"
    },
    "functionality": {
      "title": "Функционал, решающий все <br /> возможные задачи агентства",
      "listItem1": "Выведите личные и онлайн встречи с клиентами на принципиально новый уровень. В Genie Map мы загружаем логотип и название вашего агентства. Вы получаете презентабельную брендированную платформу.",
      "listItem2": "Используйте Genie Map как обучающую платформу для брокеров с небольшим опытом работы.",
      "block1Title": "Актуализация юнитов",
      "block1Description": "Наша карта обновляется каждый день. Вы всегда будете знать о доступных юнитах без бесконечных звонков и сообщений представителям застройщиков.",
      "block2Title": "Удобные онлайн-встречи",
      "block2Description": "Организуйте онлайн-встречу, включите демонстрацию экрана и наглядно покажите всю необходимую информацию в брендированном интерфейсе вашего агентства: доступные проекты, районы и инфраструктуру.",
      "block3Title": "Описания районов ОАЭ",
      "block3Description": "Используйте широкую библиотеку с описаниями районов ОАЭ. Развивайте свои знания рынка и экспертность, чтобы всегда предлагать релевантные варианты клиентам.",
      "block4Title": "Информация о каждом проекте",
      "block4Description": "Расскажите клиенту подробную информацию о каждом интересующем его проекте. Ответьте на все вопросы и успешно закройте клиента.",
      "block5Title": "База контактов sales-менеджеров",
      "block5Description": "Воспользуйтесь базой контактов sales-менеджеров для расширения базы девелоперов вашего агентства.",
      "block6Title": "Глобальная конфигурация",
      "block6Description": "Настройте окружение платформы под себя и своего клиента. Выберите один из 2-х языков и 6-x валют. Сделайте поиск удобнее, а выдачу персонализированной."
    },
    "joinForm": {
      "title": "Начните пользоваться <br /> GenieMap уже сегодня",
      "description": "Оставьте заявку на подключение, и наш менеджер <br /> оперативно свяжется с вами для уточнения деталей.",
      "name": "Ваше имя",
      "tel": "Ваш телефон",
      "position": {
        "name": "Позиция",
        "select": "Выбрать",
        "owner": "Владелец/топ-менеджер агентства",
        "broker": "Брокер",
        "developer": "Застройщик",
        "freelancer": "Фрилансер",
        "realEstateBuyer": "Покупатель недвижимости"
      },
      "companyName": "Название компании",
      "privacyPolicy": "Я согласен с политикой обработки <a href=\"./privacy-policy.html\" target=\"_blank\">персональных данных</a>", 
      "join": "Запросить бесплатный доступ",
      "note": "Бесплатный доступ на 3 дня"
    },
    "successForm": {
      "title": "Благодарим вас <br /> за отправку заявки",
      "description": "Наш менеджер скоро свяжется с вами <br /> для уточнения деталей."
    }
  },
  "footer": {
    "social": {
      "title": "GenieMap в соцсетях"
    },
    "legalInformation": {
      "title": "Юридическая информация",
      "privacyPolicy": "Политика обработки <br /> персональных данных"
    },
    "contact": {
      "title": "Связаться с нами"
    }
  },
  "cookie": {
    "text": "Мы используем файлы cookie для улучшения качества просмотра сайта. Нажав кнопку \"Принимаю\", вы соглашаетесь с использованием файлов cookie.",
    "button": "Принимаю"
  }
}